import { motion } from 'framer-motion';
import Link from 'next/link';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  Swiper as ReactSwiper,
  SwiperSlide as ReactSwiperSlide,
} from 'swiper/react';

import DotList from '@/components/comic/dot-list';
import type { Genre } from '@/interfaces/genre';
import type { Manga } from '@/interfaces/manga';

const HomeBannerSwiper = ({ datas }: { datas: Manga[] }) => {
  return (
    <ReactSwiper spaceBetween={10} breakpoints={{}} slidesPerView={1} loop>
      {datas.map((item) => (
        <ReactSwiperSlide key={item.id}>
          <Link href={`/title/${item.id}`}>
            <motion.div className="relative w-full overscroll-x-auto rounded-md">
              <LazyLoadImage
                src={item.cover.url}
                className="h-56 w-screen rounded-lg object-cover"
                effect="blur"
              />
              <div className="absolute inset-x-0 bottom-0 flex h-full w-full flex-col items-start justify-end gap-y-2 rounded-b-lg bg-gradient-to-t from-black/80 p-4">
                <p className="line-clamp-1 text-3xl uppercase text-white">
                  {item.name}
                </p>
                <DotList>
                  {item.genres.map((genre: Genre) => (
                    <p key={genre.id} className="min-w-max text-lg text-white">
                      {genre.name}
                    </p>
                  ))}
                </DotList>
              </div>
            </motion.div>
          </Link>
        </ReactSwiperSlide>
      ))}
    </ReactSwiper>
  );
};
export default HomeBannerSwiper;
