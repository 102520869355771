import { useInfiniteQuery } from '@tanstack/react-query';

import type { ApiResponse } from '@/interfaces/api-response';
import type { Manga } from '@/interfaces/manga';
import http from '@/libs/http';

const fetchInfiniteMangas = async (
  page: number,
  take: number,
  keyword: string,
  genreId: string,
  typeManga: string,
  languageId: string
): Promise<ApiResponse<Manga[]>> => {
  const response = (await http
    .get(`manga`, {
      searchParams: {
        page,
        take,
        keyword,
        genreId,
        typeManga,
        languageId,
      },
    })
    .json()) as ApiResponse<Manga[]>;

  return response;
};

const useInfiniteMangas = (
  pageSize: number,
  keyword: string,
  genreId: string,
  typeManga: string,
  languageId: string
) => {
  return useInfiniteQuery({
    queryKey: [
      'infiniteMangas',
      pageSize,
      keyword,
      genreId,
      typeManga,
      languageId,
    ],
    queryFn: ({ pageParam }) =>
      fetchInfiniteMangas(
        pageParam,
        pageSize,
        keyword,
        genreId,
        typeManga,
        languageId
      ),
    enabled: true,
    getNextPageParam: (lastPage) =>
      lastPage.meta.hasNextPage ? lastPage.meta.page + 1 : undefined,
    getPreviousPageParam: (firstPage) =>
      firstPage.meta.hasPreviousPage ? firstPage.meta.page - 1 : undefined,
    initialPageParam: 1,
  });
};

export { fetchInfiniteMangas, useInfiniteMangas };
