import clsx from 'clsx';
import type { ReactNode } from 'react';
import React from 'react';

interface DotListProps {
  className?: string;
  children: ReactNode;
}

const DotList: React.FC<DotListProps> = ({ children, className }) => {
  const childrenLength = React.Children.count(children);

  return (
    <div
      className={clsx('line-clamp-1 flex items-center space-x-2', className)}
    >
      {React.Children.map(children, (child, index) => {
        if (!child) return null;

        if (index === childrenLength - 1) {
          return child;
        }

        return (
          <>
            {child}
            <div className="h-2 w-2 rounded-full bg-gray-500" />
          </>
        );
      })}
    </div>
  );
};

export default DotList;
