import {
  ChatBubbleOvalLeftEllipsisIcon,
  EyeIcon,
  HeartIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useColor } from 'color-thief-react';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useMangaChapterComments } from '@/hooks/queries/useMangaChapterComents';
import { useMangaLikes } from '@/hooks/queries/useMangaLike';
import { useMangaViews } from '@/hooks/queries/useMangaViews';
import type { Manga } from '@/interfaces/manga';
import { DEFAULT_PAGINATION, MangaOriginals } from '@/libs/constants';

const HorizotalScrollItem = ({
  data,
  indexManga,
}: {
  data: Manga;
  indexManga?: number;
}) => {
  const [page] = useState(DEFAULT_PAGINATION.PAGE);
  const [pageSize] = useState(DEFAULT_PAGINATION.LIMIT);

  const { data: colorText } = useColor(data.avatar.url, 'hex', {
    crossOrigin: 'Anonymous',
    quality: 10,
  });

  const { data: mangaChapterLikes } = useMangaLikes(data.id, page, pageSize);

  const { data: mangaChapterViews } = useMangaViews(data.id, page, pageSize);

  const { data: mangaComments } = useMangaChapterComments(
    data.id,
    page,
    pageSize
  );

  return (
    <Link href={`/title/${data.id}/${data.slug}`}>
      <div className="group inline-block">
        <motion.div
          whileHover={{
            width: '550px',
            transition: { duration: 0.5 },
          }}
          className="relative z-20 h-44 w-28 cursor-pointer bg-transparent lg:h-60 lg:w-44 2xl:h-80 2xl:w-56"
        >
          <LazyLoadImage
            src={data.avatar.url}
            alt={data.name}
            className="group-hover:scale-none h-44 w-screen scale-100 rounded-lg border border-default-300 group-hover:object-cover lg:h-60 2xl:h-80"
            effect="blur"
            aria-label="avatar"
          />
          <div className="absolute left-0 top-0 hidden h-full w-full rounded-lg bg-black/80 lg:group-hover:block">
            <div className="flex h-full w-44 flex-col items-start justify-end gap-y-2 p-5">
              <p
                className="text-2xl font-normal"
                style={{ color: colorText || 'white' }}
              >
                {data.name}
              </p>
              <div className="flex flex-row gap-x-2">
                <div className="flex flex-row items-center gap-x-1">
                  <HeartIcon width={24} height={24} className="text-red-500" />
                  <p className="text-lg font-normal uppercase tracking-wider text-white">
                    {mangaChapterLikes?.meta.itemCount}
                  </p>
                </div>
                <div className="flex flex-row items-center gap-x-1">
                  <EyeIcon width={24} height={24} className="text-green-500" />
                  <p className="text-lg font-normal uppercase tracking-wider text-white">
                    {mangaChapterViews?.meta.itemCount}
                  </p>
                </div>
                <div className="flex flex-row items-center gap-x-1">
                  <ChatBubbleOvalLeftEllipsisIcon
                    width={24}
                    height={24}
                    className="text-sky-500"
                  />
                  <p className="text-lg font-normal uppercase tracking-wider text-white">
                    {mangaComments?.meta.itemCount}
                  </p>
                </div>
              </div>
              <p className="text-lg font-semibold text-white">
                {data.chapters.length} Chapters
              </p>
            </div>
          </div>
          <div className="absolute right-2 top-2 flex flex-col items-end justify-center gap-y-1 lg:flex-row lg:gap-x-2">
            <div
              className={clsx(
                'flex items-center justify-center rounded-lg px-2',
                {
                  'bg-lime-400':
                    data.originalsPaidNone === MangaOriginals.COMPLETED,
                  'bg-neon-blue':
                    data.originalsPaidNone === MangaOriginals.ONGOING,
                  'bg-indigo':
                    data.originalsPaidNone === MangaOriginals.ONESHOT,
                }
              )}
            >
              <p
                className={clsx(
                  'text-xs font-light capitalize lg:text-base',
                  data.originalsPaidNone === MangaOriginals.COMPLETED
                    ? 'text-black'
                    : 'text-white'
                )}
              >
                {data.originalsPaidNone.replace(/_/g, ' ')}
              </p>
            </div>
          </div>
        </motion.div>
        <p
          className={clsx(
            'mt-2 text-base font-normal text-black',
            indexManga === 0 || indexManga === 2 || indexManga === 4
              ? 'text-white'
              : 'text-black'
          )}
        >
          {data.name}
        </p>
      </div>
    </Link>
  );
};
export default HorizotalScrollItem;
