'use client';

import { useSession } from 'next-auth/react';
import { useMemo, useState } from 'react';

import { useInfiniteLanguages } from '@/hooks/queries/useInfiniteLanguages';
import { useInfiniteMangas } from '@/hooks/queries/useInfiniteMangas';
import type { ApiResponse } from '@/interfaces/api-response';
import type { Language } from '@/interfaces/language';
import type { Manga } from '@/interfaces/manga';
import { DEFAULT_PAGINATION, LanguageStatuses } from '@/libs/constants';
import { useInfiniteScroll } from '@/libs/hooks/use-infinite-scroll';

import HorizontalScroll from '../../horizontal-scroll';
import HorizotalScrollItem from '../../horizontal-scroll/item';

const AllManga = () => {
  const { data: session } = useSession();
  const [pageSize] = useState(DEFAULT_PAGINATION.LIMIT);
  const [keyword] = useState<string>('');
  const [genreId] = useState<string>('');
  const [typeManga] = useState<string>('');
  const inifiniteLanguages = useInfiniteLanguages(pageSize);

  const languages = useMemo(() => {
    return inifiniteLanguages.data?.pages.reduce(
      (acc: Language[], page: ApiResponse<Language[]>) => {
        return [...acc, ...page.data];
      },
      []
    );
  }, [inifiniteLanguages]);

  const lastElementLanguagesRef = useInfiniteScroll(
    inifiniteLanguages.fetchNextPage,
    inifiniteLanguages.hasNextPage,
    inifiniteLanguages.isFetching
  );

  const currentLanguageId = useMemo(() => {
    // Use the session's current language ID if available
    if (session?.user.currentLanguageId) {
      return session.user.currentLanguageId;
    }

    // Fallback to English language if session ID is not available
    const englishLanguage = languages?.find(
      (item: Language) => item.slug === LanguageStatuses.ENGLISH
    );
    return englishLanguage ? englishLanguage.id : '';
  }, [session, languages]);

  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteMangas(
    pageSize,
    keyword,
    genreId,
    typeManga,
    currentLanguageId
  );

  const mangas = useMemo(() => {
    return data?.pages.reduce((acc: Manga[], page: ApiResponse<Manga[]>) => {
      return [...acc, ...page.data];
    }, []);
  }, [data]);

  const lastElementRef = useInfiniteScroll(
    fetchNextPage,
    hasNextPage,
    isFetching
  );
  const itemComicUpdated: React.FC<{ data: Manga; indexManga?: number }> = ({
    data,
    indexManga,
  }) => {
    return <HorizotalScrollItem data={data} indexManga={indexManga} />;
  };
  return (
    <div
      className="flex w-full flex-col gap-y-5"
      ref={lastElementRef || lastElementLanguagesRef}
    >
      <HorizontalScroll
        datas={mangas as Manga[]}
        itemGrid={itemComicUpdated}
        title="All TITLE"
      />
    </div>
  );
};
export default AllManga;
