'use client';

import {
  ChatBubbleOvalLeftEllipsisIcon,
  EyeIcon,
  HeartIcon,
} from '@heroicons/react/24/solid';
import { Skeleton } from '@nextui-org/react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useSession } from 'next-auth/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useInfiniteMangas } from '@/hooks/queries/useInfiniteMangas';
import { useMangaChapterComments } from '@/hooks/queries/useMangaChapterComents';
import { useMangaLikes } from '@/hooks/queries/useMangaLike';
import { useMangaViews } from '@/hooks/queries/useMangaViews';
import type { ApiResponse } from '@/interfaces/api-response';
import type { Genre } from '@/interfaces/genre';
import type { Manga } from '@/interfaces/manga';
import { DEFAULT_PAGINATION, MangaOriginals } from '@/libs/constants';
import { useInfiniteScroll } from '@/libs/hooks/use-infinite-scroll';
import { randomElements } from '@/utils';

import ButtonReadComic from '../../button-read-comic';
import DotList from '../../dot-list';
import HomeBannerSwiper from './home-banner-swiper';

const HomeBanner = () => {
  const { data: session } = useSession();

  const [page] = useState(DEFAULT_PAGINATION.PAGE);
  const [pageSize] = useState(DEFAULT_PAGINATION.LIMIT);
  const [keyword] = useState<string>('');
  const [genreId] = useState<string>('');
  const [typeManga] = useState<string>('');
  const [index, setIndex] = useState<number>(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteMangas(
    pageSize,
    keyword,
    genreId,
    typeManga,
    session?.user.currentLanguageId || ''
  );

  const mangas = useMemo(() => {
    return data?.pages.reduce((acc: Manga[], page: ApiResponse<Manga[]>) => {
      return [...acc, ...page.data];
    }, []);
  }, [data]);

  const randomMangas = useMemo(() => {
    if (mangas && mangas.length > 0) {
      return randomElements(mangas); // No need to pass `mangas.length`, we shuffle all of them
    }
    return [];
  }, [mangas]);

  const activeSlide = useMemo(() => {
    if (randomMangas && randomMangas.length > 0) {
      const safeIndex = Math.min(index, randomMangas.length - 1);
      return randomMangas[safeIndex] ?? null;
    }
    return null;
  }, [randomMangas, index]);

  const { data: mangaChapterLikes } = useMangaLikes(
    activeSlide?.id as string,
    page,
    pageSize
  );

  const { data: mangaChapterViews } = useMangaViews(
    activeSlide?.id as string,
    page,
    pageSize
  );

  const { data: mangaComments } = useMangaChapterComments(
    activeSlide?.id as string,
    page,
    pageSize
  );

  const lastElementRef = useInfiniteScroll(
    fetchNextPage,
    hasNextPage,
    isFetching
  );

  useEffect(() => {
    if (randomMangas.length > 0) {
      // Clear existing timer, if any
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }

      // Set new interval
      timerRef.current = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % randomMangas.length);
      }, 10000);

      // Cleanup on unmount
      return () => {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      };
    }

    // Ensure the effect always returns a value
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [randomMangas]); // Only depend on randomMangas, not timer

  return (
    <div
      className="flex flex-col overflow-hidden px-4 pt-22 lg:px-0"
      ref={lastElementRef}
    >
      {activeSlide ? (
        <div className="group relative z-10 hidden h-[550px] w-full lg:flex">
          <motion.div
            key={activeSlide?.id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <LazyLoadImage
              src={activeSlide?.cover.url}
              className="h-[550px] w-screen object-cover"
              effect="blur"
            />
          </motion.div>
          <div className="absolute inset-0 h-full w-full bg-gradient-to-r from-black/80">
            <div className="mx-auto flex h-full w-full bg-transparent px-4 lg:px-16">
              <motion.div
                key={activeSlide?.id}
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                className="flex w-1/2 flex-col items-start justify-center gap-y-3 bg-transparent"
                transition={{ ease: [0.33, 1, 0.68, 1], duration: 1.5 }}
              >
                <p className="line-clamp-2 text-4xl font-normal uppercase tracking-wider text-white">
                  {activeSlide?.name ?? 'no'}
                </p>
                <div className="flex w-full flex-row items-center gap-x-3">
                  <div className="flex flex-row items-center gap-x-1">
                    <HeartIcon
                      width={24}
                      height={24}
                      className="text-red-500"
                    />
                    <p className="text-lg font-normal uppercase tracking-wider text-white">
                      {mangaChapterLikes?.meta.itemCount}
                    </p>
                  </div>
                  <div className="flex flex-row items-center gap-x-1">
                    <EyeIcon
                      width={24}
                      height={24}
                      className="text-green-500"
                    />
                    <p className="text-lg font-normal uppercase tracking-wider text-white">
                      {mangaChapterViews?.meta.itemCount}
                    </p>
                  </div>
                  <div className="flex flex-row items-center gap-x-1">
                    <ChatBubbleOvalLeftEllipsisIcon
                      width={24}
                      height={24}
                      className="text-sky-500"
                    />
                    <p className="text-lg font-normal uppercase tracking-wider text-white">
                      {mangaComments?.meta.itemCount}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row gap-x-3">
                  <div
                    className={clsx(
                      'flex items-center justify-center rounded-lg px-2',
                      {
                        'bg-lime-400':
                          activeSlide?.originalsPaidNone ===
                          MangaOriginals.COMPLETED,
                        'bg-neon-blue':
                          activeSlide?.originalsPaidNone ===
                          MangaOriginals.ONGOING,
                        'bg-indigo':
                          activeSlide?.originalsPaidNone ===
                          MangaOriginals.ONESHOT,
                      }
                    )}
                  >
                    <p
                      className={clsx(
                        'text-xs font-light capitalize lg:text-base',
                        activeSlide?.originalsPaidNone ===
                          MangaOriginals.COMPLETED
                          ? 'text-black'
                          : 'text-white'
                      )}
                    >
                      {activeSlide?.originalsPaidNone.replace(/_/g, ' ')}
                    </p>
                  </div>

                  <div className="flex flex-row gap-x-4">
                    <DotList>
                      {activeSlide?.genres.map((genre: Genre) => (
                        <p
                          key={genre.id}
                          className="min-w-max text-lg text-white"
                        >
                          {genre.name}
                        </p>
                      ))}
                    </DotList>
                  </div>
                </div>
              </motion.div>
              <div className="flex w-1/2">
                <ButtonReadComic data={activeSlide as Manga} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Skeleton>
          <div className="h-[550px] w-screen" />
        </Skeleton>
      )}
      <div className="mt-20 flex w-full flex-row lg:hidden">
        <HomeBannerSwiper datas={randomMangas} />
      </div>
    </div>
  );
};
export default HomeBanner;
